.navbar {
  box-shadow: $box-shadow;
  background-color: $main-white;
  height: 60px;
  margin-bottom: 1.4rem;
  z-index: 90;
}
.navbar .form-control {
  padding: 0.75rem 1rem;
}

.form-control-search {
  width: 282px;
  height: 40px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #7070705c;
  border-radius: 4px;
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: $box-shadow;
}

.user-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  color: $dark-text;
  margin-bottom: 0;
}

.user-role {
  font-size: 10px;
  margin-bottom: 0;
  color: $dark-text;
}

.user-info-dropdown {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  inset: 0px 0px auto auto !important;
  border: none;
  box-shadow: $box-shadow;
}
