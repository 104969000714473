.sidebar {
  max-height: 100vh;
  background-color: $main-white;
  color: $dark-text;
  z-index: 100; /* Behind the navbar */
  box-shadow: $box-shadow;
  max-width: 260px;
  min-width: 260px;
  width: 260px;
  overflow-x: hidden;
  transition:
    margin-left 0.35s ease-in-out,
    left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;

  .logo-container {
    height: 60px;
    max-height: 60px;
    width: 100%;
    position: relative;
    box-shadow: $box-shadow;

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 1.6rem;
      object-fit: contain;
    }

    .env-name {
      color: $main-white;
      font-size: 0.7rem;
      font-weight: 500;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .sidebar-content {
    overflow-y: auto;
    max-height: calc(100vh - 60px);
  }
}

.nav-item {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.4rem 0.5rem;
}

.sidebar .nav-link {
  font-weight: normal;
  height: 48px;
  font-size: 14px;
  color: $dark-text;
  display: flex;
  align-items: center;
}

.sidebar .nav-link.active {
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: $link-color;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
}

@media (min-width: 1px) and (max-width: 990px) {
  body:not(.show) .sidebar.collapse {
    margin-left: 0;
  }
}

@media (min-width: 1px) and (max-width: 990px) {
  body:not(.show) .sidebar {
    margin-left: -260px;
  }
}

.sidebar-accordion-bg1 {
  background-color: #fff8e0;
}

.sidebar-accordion-bg2 {
  background-color: #f0fafe;
}

.sidebar-accordion-bg3 {
  background-color: #f7f0fb;
}

.sidebar-accordion-bg4 {
  background-color: #effaf3;
}

.sidebar-accordion-bg5 {
  background-color: #fdf1f7;
}

.sidebar-accordion-bg6 {
  background-color: #f6f0eb;
}

.sidebar-accordion-bg7 {
  background-color: #f1f0fc;
}
