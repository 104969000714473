.checklist-boxes {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.marquee-container {
  position: relative;
  overflow: hidden;
  height: 1.2rem;
}

.marquee {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  transform: translateX(0%);
  animation: marquee 20s linear infinite;
}
.not-published-status {
  background-color: #f7cb73;
  padding: 6px;
  font-style: italic;
  font-size: 0.75rem;
  font-weight: 400;
  border-radius: 4px;
}

.published-status {
  background-color: #108826;
  padding: 6px;
  font-style: italic;
  font-size: 0.75rem;
  color: $layout-bg;
  font-weight: 400;
  border-radius: 4px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.next-position {
  border: 2px solid $green;
  opacity: 0.2;
  transition: 2s ease-in;
}

.checklist-action-button {
  color: $primary-color;
  display: flex;
  align-items: center;
  border: 1px solid $primary-color;
  border-radius: 4px;
  padding: 2px;
}

.checklist-action-button:after {
  display: none;
}
