.react-select__control {
  min-height: 38px !important;
  border-radius: 0.375rem !important;
  border: 1px solid #ced4da !important;
}

.react-select__placeholder {
  font-size: 0.8rem;
}

.react-select__control--is-focused {
  border: none !important;
}

.filter-select__control {
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
  min-width: 200px;
  width: "100%";
}

.filter-select__placeholder {
  font-size: 0.8rem;
  overflow: hidden;
}

// Outlet Switch(.outlet-switch) select used in GM sidebar
.outlet-switch__control {
  min-height: 35px !important;
  font-size: 0.8rem !important;
  border: none !important;
  outline: none !important;
  background-color: $primary-color !important;
  color: $main-white !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.15rem !important;
  min-width: 170px;
}

.outlet-switch__control:hover {
  cursor: pointer;
}

.outlet-switch__placeholder {
  font-size: 0.8rem !important;
  color: $main-white !important;
}

.outlet-switch__control--is-focused {
  border: none !important;
  outline: none !important;
}

.outlet-switch__menu {
  border-radius: 0.15rem !important;
  margin-top: 2px !important;
  min-width: 170px;
}

.outlet-switch__input,
.outlet-switch__single-value {
  color: $main-white !important;
}

.outlet-switch__menu-list {
  padding-top: 0 !important;
  font-size: 0.8rem !important;
  padding-bottom: 0 !important;
  min-width: 170px;
}

// Role Switch(.role-switch) select used to switch role
.role-switch__control {
  min-height: 35px !important;
  font-size: 0.8rem !important;
  border: none !important;
  outline: none !important;
  background-color: $primary-color !important;
  color: $main-white !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.15rem !important;
  min-width: 170px;
}

.role-switch__control:hover {
  cursor: pointer;
}

.role-switch__placeholder {
  font-size: 13px !important;
  color: $main-white !important;
}

.role-switch__control--is-focused {
  border: none !important;
  outline: none !important;
}

.role-switch__menu {
  border-radius: 0.15rem !important;
  margin-top: 2px !important;
  min-width: 170px;
}

.role-switch__input,
.role-switch__single-value {
  color: $main-white !important;
}

.role-switch__menu-list {
  padding-top: 0 !important;
  font-size: 0.8rem !important;
  padding-bottom: 0 !important;
  min-width: 170px;
}
