.section-container {
  background-color: transparent;

  background-image: linear-gradient(170deg, #aa37fd 70%, #f4f4f4 20%);
  height: 100vh;
  width: 100%;
  padding: 3% 0% 0% 0%;

  .header {
    padding: 10px 30px;

    img {
      height: 2.8rem;
      max-width: 100%;
    }
  }

  .details-container {
    color: $main-white;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;

    h3 {
      font-size: 2.2rem;
      margin-bottom: 0.8rem;
      font-weight: 100;
    }

    .contact-form {
      .contact-input {
        height: 48px !important;
        border-radius: 4px;
        ::placeholder {
          color: #000;
        }
      }
      .message-input {
        border-radius: 4px;
        ::placeholder {
          color: #000;
        }
      }
      .submit-button {
        width: 100px;
        height: 48px;
        background-color: #ffffff;
        color: #704685;
        font-size: 1.1rem;
        font-weight: normal;
        text-transform: uppercase;
      }
      .return-button {
        width: 100px;
        height: 48px;
        background-color: transparent;
        color: $dark-text;
        font-size: 1.1rem;
        font-weight: normal;
        text-transform: uppercase;
        border: 0;
      }
    }
  }

  .mobile-container {
    display: flex;

    img {
      height: 100%;
      width: 60%;
      margin: 0 auto;
    }
  }
}
