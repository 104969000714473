.custom-full-size-radio input {
  display: none;
}

.custom-full-size-radio label {
  border: 1px solid $border-muted;
  border-radius: 0.35rem;
  min-width: 5rem;
  margin: 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  font-size: 0.75rem;
}

.custom-full-size-radio input:checked + label {
  border-color: $link-color;
  border-width: 1px;
}
.custom-full-size-radio label:hover {
  border-color: $link-color;
}

.custom-full-size-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.circle {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: $main-white;
  margin-right: 0.5rem;
  position: relative;
  border: 1px solid $primary-color;
}

.custom-full-size-radio input:checked + label span.circle::before {
  content: "";
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $link-color;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.text {
  display: flex;
  align-items: center;
}
