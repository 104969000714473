.dropdown-divider {
  border-bottom: 1px solid $light-text;
  opacity: 0.18;
}

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
  max-height: 100vh;
}

.main-content {
  background-color: $layout-bg;
  min-height: 100vh;
  min-width: 0;
  max-height: 100vh;
  overflow-y: auto;
  transition:
    margin-left 0.35s ease-in-out,
    left 0.35s ease-in-out,
    margin-right 0.35s ease-in-out,
    right 0.35s ease-in-out;
  width: 100%;
}

.inner-container {
  min-height: calc(100vh - 60px);
}

.light-text {
  color: $light-text;
  font-weight: normal;
}

.primary-text {
  color: $primary-color;
}

.link-text {
  color: $link-color;
}

.primary-bg {
  background-color: $primary-color;
  color: $main-white;
}

.green-bg {
  background-color: $green;
  color: $main-white;
}

.orange-bg {
  background-color: $orange;
  color: $main-white;
}

.light-blue-bg {
  background-color: #f3e9ff;
  color: $dark-text;
}

.btn-sm {
  padding: 0.28rem 0.8rem;
  font-size: 0.85rem;
  border-radius: 0.15rem;
}

.form-label {
  font-size: 0.8rem !important;
  font-weight: normal;
  color: $light-text;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.error-texts {
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: #dc3545;
}

.btn-md {
  height: 40px;
}

.btn:active {
  background-color: #3e8e41;
  box-shadow: 0px 3px 6px #00000029;
  transform: translateY(4px);
}

.links {
  text-decoration: none;
  color: $link-color;
  font-size: 0.8rem;
}

p,
span {
  font-size: 0.875rem;
  margin-bottom: 0;
}

::placeholder {
  font-size: 0.75rem;
}

.modal-content {
  // box-shadow: $box-shadow;
  border: none;
}

.files-dropzone {
  height: 5rem;
  width: 100%;
  border: 1px dashed $link-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  color: $light-text;
  font-size: 0.8rem;
}

.image-dropzone {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: $light-text;
  padding: 0.45rem;
  background-color: $sidebar-bg;
  cursor: pointer;
  margin-top: 7%;
}

.accordion-button {
  font-size: 0.85rem;
}

.accordion-button:focus {
  outline: none !important;
}

.breadcrumb {
  margin-bottom: 0;

  .breadcrumb-item {
    font-size: 0.7rem;
    font-weight: lighter;

    a {
      text-decoration: none;
    }
  }
}

.task-schedule-mod,
.task-schedule-time,
.createShift {
  background-color: #f3e9ff;
}

.task-sceduler {
  font-size: 13px;
}

.shiftName,
.createShift {
  font-size: 13px;
}

.shiftInterval {
  font-size: 10px;
}

.edit,
.create {
  color: #330963;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.remove {
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}
.shiftAlter {
  font-size: 12px;
}
.offcanvas {
  --bs-offcanvas-width: 19%;
}

.offcanvas-backdrop.show {
  opacity: 0.2;
}

.days,
.timimgs {
  background-color: #f2e7f9;
}

.tskScdlrDsplySpcn,
.isError {
  font-size: 13px;
}

.tasksList {
  z-index: 0 !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.card {
  border-radius: 0.3rem !important;
  border: 0;
}

.button {
  border-radius: 0.3rem !important;
}

.form-check-input {
  border-color: $light-text !important;
}
