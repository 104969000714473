.welcomeName {
  display: flex;
  font-size: small;
  color: black;
  margin-left: 5%;
  margin-top: 2%;
}

.leftSideBar {
  background-color: $sidebar-bg;
  min-height: "100%";
  overflow: auto;

  .holisto-img {
    height: 33px;
    width: auto;
  }

  .img-box {
    width: 14rem;
    height: 14rem;
  }

  .org-logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
    // mix-blend-mode: dark;
    // background-color: rgba($color: $main-white, $alpha: 0.75);
    padding: 0.5rem;
  }

  .title {
    font-size: 1.2rem;
    color: $main-white;
    text-align: center;
    font-weight: 600;
  }
}

.multistep-progress-container {
  background-color: #f5ecff;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;

  .stepDot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.8rem;
    height: 0.8rem;
    background-color: $primary-color;
    border-radius: 0.4rem;
    margin: 0 4px;
  }

  .stepInnerDot {
    background-color: $main-white;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.2rem;
  }

  .disabled {
    width: 0.8rem;
    height: 0.8rem;
    background-color: #411a6e47;
    border: 1px solid #411a6e;
    border-radius: 0.4rem;
    margin: 0 4px;
  }

  .disabled-text {
    color: #655080;
  }

  .progress-container {
    min-width: 90px;
    width: "auto";
  }

  .progress {
    height: 4px;
    background-color: #b096cc;
    border-radius: 2px;
    margin: 0 4px;

    .progress-bar {
      background-color: #411a6e;
      border-radius: 2px;
    }
  }
}

.questions-container {
  .question-section {
    margin-bottom: 1rem;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .question-count {
      font-size: 1rem;
      font-weight: 600;
    }
    h4 {
      font-size: 1.7rem;
      font-weight: 600;
    }
  }

  .question-title {
    font-size: 0.9rem;
    font-weight: medium;
    margin-bottom: 10px;
    padding-left: 2px;
  }

  .form-control {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    height: 40px;
  }

  ::placeholder {
    color: #656565;
    font-size: 0.75rem;
  }

  .delete-button {
    border-radius: 12px;
    background-color: $sidebar-bg;
    height: 24px;
    width: 24px;
    text-align: center;
    border: none;
    color: $main-white;
    position: relative;

    .cross {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input-area {
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.temp-range-container {
  background: #f5f2f2;
  color: #373636;
}

.assets-form-container {
  min-height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: hidden;
}

.business-details-container {
  min-height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}

.wizard-logo-container {
  width: 50%;
  margin: 0 auto;
  object-fit: contain;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploaded-logo {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.time-interval-text {
  font-size: 13px;
  font-weight: bold;
  color: #330963;
  margin-left: -5px;
}
