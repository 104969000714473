.dot-typing {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  color: $main-white;
  box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
    10014px 0 0 0 $main-white;
  animation: dot-typing 1s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
  50% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px -10px 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px -10px 0 0 $main-white;
  }
  100% {
    box-shadow: 9984px 0 0 0 $main-white, 9999px 0 0 0 $main-white,
      10014px 0 0 0 $main-white;
  }
}
