.banner-left {
  position: relative;
  img {
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .banner-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: 54px;
    width: 50%;
  }
}

.banner-right {
  background-color: $layout-bg;
  .title {
    font-size: 32px;
    margin-bottom: 18px;
  }

  a {
    color: $sidebar-bg;
  }

  .logo-card {
    // box-shadow: 0px 3px 6px #00000029;
    box-shadow: $box-shadow;
    border-radius: 0;
    border: 0;
    padding: 2rem;

    .logo {
      height: 180px;
      width: 180px;
      background-color: #f5ecff;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .info-text {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .ellipses {
    background-color: $sidebar-bg;
    height: 10px;
    width: 42px;
    border-radius: 5px;
    margin: 0 4px;
  }

  .inactive-ellipse {
    background-color: #54298545;
    width: 24px;
  }
}
