.headerContainer {
  padding: 1.2rem;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
}
.name {
  font-size: 18px;
  font-weight: 600;
}
.needHelp {
  font-size: 14px;
  font-weight: normal;
}
.contact {
  text-decoration: underline;
  color: #411a6e;
  margin-left: 9px;
  font-size: 16px;
  font-weight: 600;
}
