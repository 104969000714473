.count-card {
  height: 140px;
  background: $main-white;
  color: $dark-text;
  box-shadow: $box-shadow;

  .count-text {
    font-size: 1.6rem;
    font-weight: normal;
    letter-spacing: 0px;
  }

  .icon-container {
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff4e5;
  }

  .card-img {
    height: 2rem;
    width: 2rem;
  }

  .sub-title {
    font-size: 0.85rem;
    font-weight: normal;
    text-transform: uppercase;
    color: $light-text;
  }
}

.data-cards {
  box-shadow: $box-shadow;
  border-radius: 4px;
  padding: 20px 14px;
  border: 0;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;

  .staff-group {
    font-size: 0.8rem;
    padding: 0px 4px;
  }
  .title {
    font-weight: 500;
    letter-spacing: 0.02em;
    font-size: 1.1rem;
    line-height: 1.5;
    text-transform: capitalize;
    color: $light-text;
  }
}

.quick-links {
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0px;
  color: $primary-color;
  text-transform: capitalize;
  padding-bottom: 1rem;
  text-decoration: none;
}

.progress-text {
  font-weight: 600;
  font-size: 12px;
  color: #262626;
}

.progress {
  height: 14px;
  background-color: #f3ecfa;
  border-radius: 11px;

  .progress-bar {
    background-color: #764ca5;
    border-radius: 11px;
  }
}

.list-cards-container {
  max-height: calc(100vh - 340px);
  min-height: calc(100vh - 340px);
  overflow-y: auto;
  overflow-x: hidden;
}

.user-role {
  font-size: 10px;
  font-weight: 200;
  padding: 4px;
  margin: 0 8px;
  background-color: $sidebar-bg;
  color: $main-white;
  border-radius: 8px;
}

.reports-iframe {
  height: calc(100vh - 180px);
  width: 100%;
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
}

.asset-icon {
  height: 4rem;
  width: 4rem;
  object-fit: contain;
  aspect-ratio: 16 / 9;
  margin: 0.6rem 0;
}

.profile-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: contain;
}
.user-icon-large {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  object-fit: contain;
}

.asset-value {
  font-size: 0.85rem;
  color: $dark-text;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.asset-lable {
  font-size: 0.65rem;
  color: $light-text;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.yellow-badge {
  color: $main-white !important;
  background-color: $orange;
  font-size: 0.7rem;
  padding: 1px 4px;
  border-radius: 2px;
}

.green-badge {
  color: $main-white !important;
  background-color: $green;
  font-size: 0.7rem;
  padding: 1px 4px;
  border-radius: 2px;
}

.red-badge {
  color: #fa5c7c !important;
  background-color: rgba(250, 92, 124, 0.18);
  font-size: 0.7rem;
  padding: 1px 4px;
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $link-color;
  color: $main-white !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f9fafd;
}

.page-title-container {
  // position: sticky;
  top: 30px;
  // transform: translateY(-50%);
  margin: -10px !important;
  margin-bottom: 15px !important;
  width: fit-content;
  z-index: 100;
}

.sticky-table-head {
  position: sticky;
  top: 0;
  background-color: $main-white !important;
  z-index: 10;
  box-shadow: $box-shadow;
}

@media only screen and (max-width: 800px) {
  .page-title-container {
    display: block;
    top: 80px;
  }
}
