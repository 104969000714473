$sidebar-bg: #542985;
$light-text: #727272;
$dark-text: #495057;
$main-white: #ffffff;
$primary-color: #330963;
$layout-bg: #f7f9fc;
$border-muted: #dee2e6;

$link-color: #727cf5;
$green: #0acf97;
$orange: #ffc35a;

$box-shadow:
  0 2px 4px rgba(0, 0, 20, 0.08),
  0 1px 2px rgba(0, 0, 20, 0.08);
